<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <span>{{ $t("message.products") }}</span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <div class="grid-content bg-purple-light d-flex justify-content-end">
            <el-row class="button_r">
              <el-button @click="saveServiceList" type="success">{{
                $t("message.save_and_close")
              }}</el-button>
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="cal" v-loading="loadingData">
      <div>
        <div class="m-3 mt-2 mb-2 mr-3">
          <el-input
            :placeholder="$t('message.filter_keyword')"
            v-model="filterText"
          >
          </el-input>
        </div>
      </div>
      <el-radio-group
        v-model="tabPosition"
        class="mx-3"
        style="margin-bottom: 30px"
        @change="handleClick"
      >
        <el-radio-button v-for="(val, key) in list" :key="key" :label="key">{{
          val.name
        }}</el-radio-button>
      </el-radio-group>

      <div class="custom-tree-container">
        <div class="block">
          <el-tree
            class="filter-tree"
            :data="data"
            show-checkbox
            :default-expanded-keys="expanded_arr"
            node-key="id"
            :props="defaultProps"
            @check-change="selected"
            :filter-node-method="filterNode"
            ref="items"
          >
            <span
              :style="
                data.color
                  ? `background: ${data.color}; color: white; line-height: 20px;`
                  : ''
              "
              style="line-height: 15px"
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <span
                style="
                  padding-right: 100px;
                  display: inline-block;
                  white-space: break-spaces;
                  line-height: 15px;
                  font-size: 14px;
                "
                >{{ data.name }}</span
              >
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";

export default {
  props: ["items"],
  mixins: [drawerChild],
  data() {
    return {
      filterText: "",
      defaultProps: {
        items: "items",
        label: "label",
        price: "price",
      },
      tabPosition: 0,
      data: [],
      selectedList: [],
      loadingData: false,
      expanded_arr: [],
    };
  },
  async created() {
    // await this.fetchData();
    // this.data = this.list;
  },
  async mounted() {},
  watch: {
    driver: {
      handler: async function (newVal, oldVal) {
        if (this.driver === true) {
          this.data = this.list;
        } else {
          this.data = [];
        }
      },
    },
    filterText(val) {
      this.$refs.items.filter(val);
    },
  },
  computed: {
    ...mapGetters({
      list: "products/relation",
    }),
    count: function () {
      return this.selectedList.length;
    },
    amount: function () {
      let price = this.selectedList.reduce(
        (a, b) => a + parseFloat(b.price || 0),
        0
      );
      return price.toFixed(2);
    },
  },
  methods: {
    ...mapActions({
      realtionList: "products/relation",
    }),
    handleClick(tab) {
      this.data = this.list[tab].services;
      this.selected_owner_branch_id = this.list[tab].id;
    },
    afterOpened() {
      this.fetchData();
    },
    fetchData() {
      this.loadingData = true;
      this.realtionList()
        .then((res) => {
          this.data = res.data.products;
          this.loadingData = false;
          function extractKeys(treeData) {
            const keys = [];
            function traverse(node) {
              keys.push(node.id);
              if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                  traverse(child);
                }
              }
            }
            for (const node of treeData) {
              traverse(node);
            }
            return keys;
          }
          this.expanded_arr = extractKeys(this.data);
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    saveServiceList() {
      this.$emit("items", this.selectedList);
      //   this.$emit("owner_branch_id", this.selected_owner_branch_id);
      this.$refs.items.setCheckedNodes([]);
      this.$refs.items.setCheckedKeys([]);
      //   this.saveList(JSON.parse(JSON.stringify(this.selectedList)))
      //     .then((res) => {
      //       this.$emit("items", this.selectedList);
      //       this.$emit("owner_branch_id", this.selected_owner_branch_id);
      //       this.$refs.items.setCheckedNodes([]);
      //       this.$refs.items.setCheckedKeys([]);
      this.resetForm("form");
      //     })
      //     .catch((err) => {});
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    afterClosed() {},
    selected() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (!element.children || element.children.length == 0) {
            this.selectedList.push(element);
          }
        }
      }
    },
    resetForm(formName) {
      this.$refs.items.setCheckedNodes([]);
      this.$refs.items.setCheckedKeys([]);
      this.$emit("c-close", { drawer: "drawerProducts" });
    },
  },
};
</script>
<style lang="scss" >
.el-tree-node__content > .custom-tree-node {
  font-size: 1.05rem;
}
.el-tree-node__items .el-tree-node__content > .custom-tree-node {
  font-size: 1rem;
}
.custom-tree-container .el-tree-node__content {
  height: auto;
}
.cal {
  .el-tree-node__content {
    position: relative;
  }
  .custom-tree-node {
    span > div {
      position: absolute;
      right: 20px;
      top: 0px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.modalCartsevis,
.modalsummservis {
  margin: 3px 20px 0 0;
  i {
    font-size: 20px;
  }
}
.modalsummservis {
  span {
    font-size: 14px;
    color: #8e8e8e;
  }
}
.modalCartsevis {
  position: relative;
  // margin-right: 15px;
  span {
    position: absolute;
    top: -12px;
    border-radius: 50%;
    background-color: #67c23a;
    font-size: 14px;
    /* padding: 3px; */
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -12px;
  }
}
</style>
