<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_status') }}.</span>
      <el-button type="success" size="small" class="mr-1">{{ $t('message.save') }}</el-button>
      <el-button type="primary" size="small" class="mr-1">{{ $t('message.save_and_close') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small">{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form-item :label="$t('message.status_0')">
              <el-input v-model="form.test1" :placeholder="$t('message.status_0')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="$t('message.view')">
              <el-input v-model="form.test1" :placeholder="$t('message.view')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      form: {
        num: 1
      }
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleChange(value) {
      console.log(value);
    }
  }
};
</script>
